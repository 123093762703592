<template>
  <form @submit="submitAnswer">
    <input
      type="text"
      v-model="answer"
      ref="answerInput"
      class="form__input"
      :placeholder="placeholderText"
    />
    <div class="input-container">
      <button class="skip__enigma" type="button" @click="skipEnigma">Passer l'énigme</button>
      <button class="answers__enigma" type="button" @click="lastEnigmas">Réponses de la veille</button>
    </div>
  </form>
</template>

<script>
export default {
  props: {
    getWordCount: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      answer: ''
    };
  },
  computed: {
    placeholderText() {
      return `On cherche ${this.getWordCount} mot${this.getWordCount > 1 ? 's' : ''}`;
    }
  },
  methods: {
    submitAnswer(event) {
      event.preventDefault();

      if (this.answer.length < 1) {
        return;
      }

      this.$emit('submit-answer', this.answer);
      this.answer = '';
    },
    skipEnigma() {
      this.$emit('skip-enigma');
      this.$refs.answerInput.focus();
    },
    lastEnigmas() {
      this.$router.push({ name: 'LastEnigmas' });
    }
  },
  mounted() {
    this.$refs.answerInput.focus();
  }
};
</script>

<style scoped>
form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

div {
  margin-bottom: 10px;
}

label {
  color: white; /* Couleur du texte en blanc */
}

.form__input {
  font-family: 'Roboto', sans-serif;
  font-size: 1.5rem;
	margin: 0 auto;
  margin-bottom: 1rem;
  padding: 1.5rem 2rem;
  border-radius: 0.2rem;
  background: #1c1c1c;
  color: #fec52c;
  border: none;
  width: 19rem;
  display: block;
  border-bottom: 0.3rem solid transparent;
  transition: all 0.3s;
  text-align: center;
  border-radius: 5rem;
  outline: none;
}

.form__input:hover {
  cursor: pointer;
  outline: none;
}

.form__input:placeholder-shown + .form__label {
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(-4rem);
  transform: translateY(-4rem);
}

.form__input::placeholder {
}

.responses {
  list-style-type: none;
  padding: 0;
  margin-top: 20px;
  color: white;
  font-size: 1.2rem;
}

.responses li {
  background-color: rgba(255, 255, 255, 0.1);
  padding: 10px;
  margin-bottom: 5px;
  border-radius: 5px;
}

.input-container {
  display: flex;
  align-items: center;
}

@media (max-width: 600px) {
  .form__input {
    width: 70vw;
    font-size: 1.4rem;
  }
}
</style>