<template>
  <div id="app">
    <h1>Les Énigmes Du Jour</h1>
    <div id="enigma">
      <template v-if="enigmasOk.length < enigmas.length">
        <vue-writer 
          v-if="currentEnigma && currentEnigma.text" 
          :key="currentEnigmaNumber" 
          :array="[currentEnigma.text]" 
          :typeSpeed="45" 
          class="enigma-text" 
          :iterations="1">
        </vue-writer>
        <SubmitAnswerForm @submit-answer="checkAnswer" @skip-enigma="skipEnigma" :get-word-count="wordCount" />
      </template>
      <template v-else-if="currentEnigmaNumber === 1">
        <div class="congratulations">
           Désolé pas d'énigmes aujourd'hui, merci de revenir demain ! 🙌
        </div>
        <div class="input-container">
          <button class="answers__enigma" type="button" @click="lastEnigmas">Réponses de la veille</button>
        </div>
      </template>
      <template v-else>
        <div class="congratulations">
           🎉 Bravo tu as trouvé toutes les énigmes du jour en <span class="timer">{{ minutes }} minute(s) et {{ seconds }} seconde(s)</span>. On se retrouve demain pour une nouvelle partie !
        </div>
          <div class="input-container">
            <button class="answers__enigma" type="button" @click="lastEnigmas">Réponses de la veille</button>
          </div>
      </template>
    </div>
    <div v-if="submittedAnswers.length" class="submitted-answers">
      <ul>
        <li v-for="(answer, index) in submittedAnswers.slice().reverse()" :key="index">{{ answer }}</li>
      </ul>
    </div>
    <div class="pills-container">
      <div 
        v-for="number in 5" 
        :key="number" 
        :class="['pill', { 'found': enigmasOk.includes(number) }, { 'current': number == currentEnigmaNumber }]">
        {{ number }}
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';  
import VueWriter from 'vue-writer';
import SubmitAnswerForm from './components/SubmitAnswerForm.vue';

export default defineComponent({
  components: {
    VueWriter,
    SubmitAnswerForm
  },
  data() {
    return {
      enigmas: [],
      currentEnigmaNumber: 1,
      submittedAnswers: [],
      enigmasOk: [],
      startTime: null,
      endTime: null,
      minutes: 0,
      seconds: 0
    };
  },
  computed: {
    currentEnigma() {
      return this.enigmas.find(enigma => enigma.number === this.currentEnigmaNumber);
    },
    wordCount() {
      if(this.currentEnigma) {
        return this.currentEnigma.answer[0].split(' ').length;
      }

      return 0;
    }
  },
  created() {
    fetch('./enigmas.json') // Assurez-vous que le chemin est correct
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        const date = new Date();
        const options = { timeZone: 'Europe/Paris', year: 'numeric', month: '2-digit', day: '2-digit' };
        const formatter = new Intl.DateTimeFormat('fr-CA', options);
        const today = formatter.format(date);

        const enigmaData = data.enigmas.find(e => e.date === today);
        if (enigmaData) {
          this.enigmas = enigmaData.enigmas;
        }
      })
      .catch(error => console.error('Error loading enigmas:', error));
  },
  mounted() {
    this.startTimer();
  },
  methods: {
    lastEnigmas() {
      console.log(this.$router);
      this.$router.push({ name: 'LastEnigmas' });
    },
    startTimer() {
      this.startTime = new Date();
    },
    stopTimer() {
      this.endTime = new Date();
      this.calculateTime();
    },
    calculateTime() {
      const timeDiff = this.endTime - this.startTime; // en millisecondes
      this.minutes = Math.floor(timeDiff / 60000);
      this.seconds = ((timeDiff % 60000) / 1000).toFixed(0);
    },
    checkAnswer(userAnswer) {
      const normalizedUserAnswer = userAnswer.trim().toLowerCase();
      const possibleAnswers = this.currentEnigma.answer.map(answer => answer.toLowerCase());

      if (possibleAnswers.includes(normalizedUserAnswer)) {
        if (this.currentEnigmaNumber <= this.enigmas.length) {
          this.enigmasOk.push(this.currentEnigmaNumber);

          this.submittedAnswers.push(userAnswer + " ✅"); // Ajouter la réponse correcte au tableau

          if (this.enigmasOk.length >= this.enigmas.length) {
            this.currentEnigmaNumber++;
            this.stopTimer();
          } else {
            this.nextEnigma();
          }
        }
      } else {
        this.submittedAnswers.push(userAnswer + " ❌"); // Ajouter la réponse incorrecte au tableau
      }
    },
    skipEnigma() {
      do {
        this.currentEnigmaNumber++;

        if (this.currentEnigmaNumber > this.enigmas.length) {
          this.currentEnigmaNumber = 1;
        }
      } while (this.enigmasOk.includes(this.currentEnigmaNumber));
    },
    nextEnigma() {
      do {
        this.currentEnigmaNumber++;

        if (this.currentEnigmaNumber > this.enigmas.length) {
          this.currentEnigmaNumber = 1;
        }
      } while (this.enigmasOk.includes(this.currentEnigmaNumber));
    }
  }
});
</script>

<style>
@font-face {
  font-family: 'Rocher';
  src: url('../public/rocher.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'RocherGreen';
  src: url('../public/RocherGreen.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Tangerine';
  src: url('../public/Tangerine-Regular.ttf');
}

@font-face {
  font-family: 'Pixelify';
  src: url('../public/PixelifySans-VariableFont_wght.ttf');
}

@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}

@keyframes blink {
  49% {
    background-color: black;
  }
  50% {
    background-color: transparent;
  }
  99% {
    background-color: transparent;
  }
}

html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden; /* Empêche le débordement horizontal */
}

#app {
  width: 100%;
  font-family: 'Roboto', sans-serif;
  text-align: center;
  color: white; /* Couleur du texte en blanc */
  background-color: black; /* Fond noir */
  min-height: 90vh; /* S'assurer que le fond couvre toute la hauteur de la page */
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Aligner les éléments vers le haut */
  align-items: center;

  #enigma {
    max-width: 50vw;
    font-family: 'Tangerine', sans-serif;

    .enigma-text, .congratulations {
      margin: 0 auto; /* Centrer le texte */
      font-size: 5rem;
      margin: 7rem 0;

      .timer {
        color: #fec52c;
      }
    }

    span.cursor {
      display: inline-block;
      width: 2px;
      margin-left: 15px;
      background-color: black;
      animation: blink 1s infinite;
    }
  }

  .submitted-answers ul {
    font-family: 'Roboto', sans-serif;
    list-style-type: none;
    padding: 0;
    margin-top: 2rem;
    color: white;
    font-size: 1.2rem;

    li {
      background-color: rgba(255, 255, 255, 0.1);
      padding: 20px;
      margin-bottom: 1rem;
      border-radius: 5px;
    }
  }

  .pills-container {
    position: absolute;
    left: 5rem;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    gap: 10px;

    .pill {
      font-family: 'Rocher', sans-serif;
      font-size: 1.5rem;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      border: 3px solid grey;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      background: #1c1c1c;
    }

    .pill.current {
      border-color: white;
      color: #fec52c;
      box-shadow: 0 0 10px white;
    }

    .pill.found {
      font-family: 'RocherGreen', sans-serif;
      color: #00ff00;
      border-color: #00ff00;
      box-shadow: 0 0 10px #00ff00;
    }
  }
}

@media (max-width: 600px) {
  #app {
    width: 100%;
    h1 {
      margin-top: 1.2em;
      font-size: 3em;
      max-width: 75vw;
    }

    #enigma {
      max-width: 90vw;
      width: 90vw;
      .enigma-text, .congratulations {
        font-size: 3rem;
        margin: 3rem 0;
      }
    }

    .pills-container {
      display: none;
    }
  }
}
</style>