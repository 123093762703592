<template>
  <div id="app">
    <main>
      <router-view></router-view> <!-- Les composants des routes se rendront ici -->
    </main>
    <footer class="footer">
      <div class="footer-content">
        <a href="mailto:lesenigmesdujourgp@gmail.com" class="contact-link">Me contacter</a> /
        <p>&copy; 2024 Les Énigmes Du Jour</p>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'App',
};
</script>

<style>
#app {
  font-family: 'Roboto', sans-serif;
  text-align: center;
  color: #2c3e50;
}

header {
  background-color: #42b983;
  padding: 1em;
}

footer.footer {
  color: white;
  text-align: center;
  padding: 1rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  background: #1c1c1c;
  .footer-content {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem; /* Espace entre les éléments */
    a {
      color: #fec52c;
    }
  }
}


h1 {
  padding-top: 2rem;
  //font-family: 'Rocher', sans-serif;
  font-family: 'Pixelify', sans-serif;
  color: #fec52c;
  font-size: 5rem;
  margin: 2rem 0;
  margin-top: 5rem;
  border-radius: 2rem;
  text-shadow: 5px 5px 0px #ffffff45;
  
  padding: 1rem;
  &:hover {
    cursor: pointer;
  }
}

.skip__enigma, .answers__enigma {
  font-family: 'Pixelify', sans-serif;
  border-radius: 2rem;
  margin-top: 2rem;
  border: 2px solid grey;
  background-color: #1c1c1c;
  box-shadow: 0 4px #1c1c1c;
  color: #fec52c;
  padding: 20px 30px;
  font-size: 1.3rem;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.2s ease;

  &:active {
    box-shadow: 0 2px #0056b3;
    transform: translateY(2px);
  }

  &:hover {
    color: #fec52c;
    transition: all 0.2s ease;
    border: 2px solid #fec52c;
    transform: translateY(-2px);
  }
}

button {
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid white;
  background-color: white;
  color: black;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: border-color 0.3s, box-shadow 0.3s;
  margin-left: 1rem;
}

@media (max-width: 600px) {
 .skip__enigma, .answers__enigma {
  font-size: 1.1rem;
 }
}
</style>
