import { createRouter, createWebHistory } from 'vue-router';
import DailyEnigmas from '@/DailyEnigmas.vue';
import LastEnigmas from '@/components/LastEnigmas.vue';

const routes = [
  {
    path: '/last_enigmas',
    name: 'LastEnigmas',
    component: LastEnigmas
  },
  {
    path: '/',
    name: 'DailyEnigmas',
    component: DailyEnigmas
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;