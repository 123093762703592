<template>
  <div class="last-enigmas">
    <h1 @click="home">Les Énigmes Du Jour</h1>
    <ul>
      <li v-for="(answer, index) in lastEnigmas" :key="index">
        <div><span class="number">{{ answer['number'] }}.</span>{{ answer['text'] }}</div>
        <div class="answer">{{ answer['answer'][0] }}</div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      lastEnigmas: [] // Remplissez ce tableau avec les réponses de la veille
    };
  },
  created() {
    fetch('./enigmas.json') // Assurez-vous que le chemin est correct
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        const yesterday = this.getYesterdayDate();
        
        const enigmaData = data.enigmas.find(e => e.date === yesterday);
        if (enigmaData) {
          this.lastEnigmas = enigmaData.enigmas;
        }
      })
      .catch(error => console.error('Error loading enigmas:', error));
  },
  methods: {
    home() {
      this.$router.push({ name: 'DailyEnigmas' });
    },
    getYesterdayDate() {
      const date = new Date();
      date.setDate(date.getDate() - 1);
      const options = { timeZone: 'Europe/Paris', year: 'numeric', month: '2-digit', day: '2-digit' };
      const formatter = new Intl.DateTimeFormat('fr-CA', options);
      const formattedDate = formatter.format(date);
      return formattedDate;
    }
  }
};
</script>

<style scoped>
.last-enigmas {
  max-width: 60vw;
  padding: 10px;
}

.last-enigmas ul {
  list-style-type: none;
  padding: 0;
}

.last-enigmas li {
  background-color: #1c1c1c;
  margin: 2rem 0;
  padding: 20px;
  font-size: 4rem;
  font-family: 'Tangerine', sans-serif;
  .number {
    font-family: 'Rocher', sans-serif;
    margin-right: 15px;
    font-size: 2rem;
  }
  .answer {
    font-family: 'RocherGreen', sans-serif;
    font-size: 2.5rem;
    margin-top: 1rem;
    color: #00ff00;
    text-transform: capitalize;
  }
}

@media (max-width: 600px) {
  .last-enigmas {
    max-width: 90vw;
    li {
      font-size: 3rem;
      .number {
        font-size: 1.5rem;
      }
      .answer {
        font-size: 2rem;
      }
    }
  }
}
</style>